//import { IMGIX_SRC, IMGIX_KEY } from "@/constants/environment"
import ImgixClient from "@imgix/js-core"

/* todo: make sure is removed before prod */
const imgixClient = new ImgixClient({
  domain: "pixura.imgix.net",
  secureURLToken: "DnnyFYUFXWEWC3YE",
})

interface ImgixParams {
  width?: string | number | null
  height?: string | number | null
  fit?:
    | "clip"
    | "crop"
    | "facearea"
    | "fill"
    | "fillmax"
    | "max"
    | "min"
    | "scale"
    | "thumb"
    | "top"
  crop?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "faces"
    | "entropy"
    | "edges"
    | "center"
  auto?: "compress" | "format"
  quality?: number | string | null
  blur?: number | null
  fm?: "jpg" | "jpeg" | "png" | "webp" | "avif"
  video?: boolean
  name?: string
}

export const generateImgixUrl = (
  imageUri: string,
  params: ImgixParams
): string => {
  if (!imageUri) {
    return ""
  }
  if (imageUri.includes("base64")) {
    return imageUri
  }
  return imgixClient.buildURL(imageUri, params)
}
